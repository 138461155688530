// Bootstrap
import {Form, Button, Alert, ButtonGroup} from "solid-bootstrap";

// Icons
import FontAwesomeIcon from "../../components/fontawesome/FontAwesomeIcon";
import {faChevronLeft, faCheckSquare} from "@fortawesome/free-solid-svg-icons";

// Lib
import moment from "moment";

// Solid
import {createEffect, createSignal, Suspense} from "solid-js";
import createPrice from "../../helpers/createPrice";
import {useStoreon} from "@storeon/solidjs";

// UI
import QuantityInput from "../../components/QuantityInput";

export default () => {
    const [state, dispatch] = useStoreon();

    // Message d'alerte.
    const [alert, setAlert] = createSignal();

    // Demande d'ajout au panier
    const [addedToCart, setAddedToCart] = createSignal();
    const [qtyInputRef, setQtyInputRef] = createSignal(null);
    const doAddToCart = async () => {

        // On demande l'ajout au panier
        await dispatch("addToCart", {
            booking: {
                variantId: state.booking.variantId,
                scheduleId: state.booking.scheduleId,
                date: moment(state.booking.date).utc().set('millisecond', 0).toDate()
            },
            quantity: qtyInputRef().value
        });
    };

    createEffect(() => {
        if (state.addToCartResult === null) return;

        // Une erreur est survenue lors de l'ajout au panier
        if (state.addToCartResult.errorCode) {
            setAlert({
                type: "danger",
                message: state.addToCartResult.message
            });
            setAddedToCart(false);
        } else {
            setAlert({
                type: "success",
                message: "Produit ajouté au panier !"
            });
            setAddedToCart(true);
        }

        dispatch("setAddToCartResult", null);

    })

    return (
        <Suspense fallback={<div class="container">Chargement de la réservation...</div>}>
            <div class="container booking">
                <h2 class="mb-4">Votre service est prêt</h2>
                <div class="row">
                    <div class="col-md">
                        <div class="add-to-cart text-center">
                            <h3 class="font-weight-normal text-uppercase text-primary">
                                {state.activities[state.booking.activityId].name}<br />
                                <span class="font-weight-bold">{moment.utc(state.booking.date).utcOffset(state.booking.utcOffset).locale('fr').format('dddd DD MMMM YYYY à HH:mm')}</span>
                            </h3>
                            <div class="m-4">
                                <div class="row">
                                    <div class="col-4 col-md">
                                        <Form.Label>Prix</Form.Label>
                                        <div class="fs-4 lh-lg">{createPrice(state.booking.price)} €</div>
                                    </div>
                                    <div class="col-8 col-md">
                                        <QuantityInput ref={setQtyInputRef} available={state.booking.available} />
                                    </div>
                                </div>
                            </div>
                            <Show when={alert()}>
                                <Alert variant={alert().type}>
                                    <Show when={alert().type === "danger"}>
                                        <Alert.Heading>Une erreur est survenue.</Alert.Heading>
                                    </Show>
                                    <p>
                                        {alert().message}
                                    </p>
                                </Alert>
                            </Show>
                            <Show when={!addedToCart()}>
                                <Button variant="primary" size="lg" onClick={doAddToCart}><FontAwesomeIcon icon={faCheckSquare} className={'mr-2'}/> Ajouter au panier</Button>
                            </Show>
                            <Show when={addedToCart()}>
                                <ButtonGroup class="d-block d-md-flex">
                                    <Button variant="outline-primary" class="d-block d-md-inline-block" size="lg" href={`/institution/${state.booking.channelToken}`}><FontAwesomeIcon icon={faChevronLeft} className={'mr-2'}/> Continuer mes réservations</Button>
                                    <Button variant="secondary" class="d-block d-md-inline-block" size="lg" href={`/order`}><FontAwesomeIcon icon={faCheckSquare} className={'mr-2'}/> Valider mon panier</Button>
                                </ButtonGroup>
                            </Show>
                        </div>
                    </div>
                    <div class="col-md mt-4 mt-md-0">
                        <div class="bg-white p-4 instructions">
                            <h4 class="text-uppercase text-primary font-weight-normal">1 - Réservez</h4>
                            <p class="text-justify">Choisissez les activités de votre choix, le jour et l'horaire disponible à cette date. Renseignez vos coordonnées et validez votre réservation.</p>
                            <h4 class="text-uppercase text-primary font-weight-normal mt-4">2 - Confirmation</h4>
                            <p class="text-justify">Vous recevrez un e-mail de confirmation de réservation.</p>
                            <h4 class="text-uppercase text-primary font-weight-normal mt-4">3 - Rendez-vous à votre activité</h4>
                            <p class="text-justify">Pour profiter de votre réservation, rendez-vous à l'accueil de l'établissement au jour et à l'heure indiqués muni de votre e-mail.</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-5 col-12">
                    <a className="btn btn-primary" href={`/institution/${state.booking.channelToken}`}><FontAwesomeIcon icon={faChevronLeft} className={'mr-2'}/> Retour</a>
                </div>
            </div>
        </Suspense>
    );
};
