import CartList from "../../../components/cart/CartList";
import CartDetails from "../../../components/cart/CartDetails";
import {useStoreon} from "@storeon/solidjs";
import {useNavigate} from "@solidjs/router";

export default () => {
    const [state, dispatch] = useStoreon();
    const navigate = useNavigate();

    // Suppression d'une ligne dans le panier
    const handleDelete = async (line) => {
        // On demande la supression au panier
        await dispatch("removeFromCart", line.id);
    };

    // Mise à jour d'une ligne dans le panier
    const handleUpdate = async (line, quantity) => {
        // On demande la supression au panier
        await dispatch("updateCart", { line, quantity });
    };

    async function doValidate() {
        const result = await state.agents.cart.validateOrder();
        if (result.errorCode) {

        } else {
            navigate("/order/customer");
        }
    }
 
    return (
        <div class="cart">
            <h2 class="mb-4">Votre panier</h2>
            <Show when={state.cart} fallback={"Votre panier est vide."}>
                <div class="row">
                    <div class="col-12 col-md-8">
                        <CartList handleUpdate={handleUpdate} handleDelete={handleDelete} order={true} />
                    </div>
                    <div class="col-12 col-md-4 mt-4 mt-md-0">
                        <CartDetails cart={state.cart} order={true} doValidate={doValidate} />
                    </div>
                </div>
            </Show>
        </div>
    );
}