import {createEffect} from "solid-js";
import {useStoreon} from "@storeon/solidjs";
import {useNavigate} from "@solidjs/router";

/*#if _ES
import {lazy} from "solid-js";
const Booking = lazy(() => import("./Booking"));
//#else */
import Booking from "./Booking";
//#endif

export default function (props) {
    const [state, dispatch] = useStoreon();
    const navigate = useNavigate();

    if (state.booking.activityId === null) {
        return navigate("/");
    }

    createEffect(() => dispatch("loadActivity", state.booking.activityId));

    return Booking();
}
