import {Switch, Match, Suspense} from "solid-js";
import OrderCart from "./steps/OrderCart";
import OrderCustomer from "./steps/OrderCustomer";
import OrderPayment from "./steps/OrderPayment";
import OrderShipping from "./steps/OrderShipping";
import OrderConfirm from "./steps/OrderConfirm";
import OrderCancel from "./steps/OrderCancel";

export default ({step}) => {
    return (
        <Suspense fallback={<div class="container">Chargement de votre commande...</div>}>
            <div class="container">
                <Switch>
                    <Match when={step() === "cart"}><OrderCart /></Match>
                    <Match when={step() === "customer"}><OrderCustomer /></Match>
                    <Match when={step() === "shipping"}><OrderShipping /></Match>
                    <Match when={step() === "payment"}><OrderPayment /></Match>
                    <Match when={step() === "confirm"}><OrderConfirm /></Match>
                    <Match when={step() === "cancel"}><OrderCancel /></Match>
                </Switch>
            </div>
        </Suspense>
    );
};
