import {Switch, Match, Suspense} from "solid-js";
import UserCreate from "./UserCreate";
import UserLogin from "./UserLogin";
import UserLost from "./UserLost";
import UserReset from "./UserReset";

export default ({action}) => {
    return (
        <div class="container">
            <Switch>
                <Match when={action() === "create"}><UserCreate /></Match>
                <Match when={action() === "lost"}><UserLost /></Match>
                <Match when={action() === "reset"}><UserReset /></Match>
                <Match when={action() === "login"}><UserLogin /></Match>
            </Switch>
        </div>
    );
};
 