import {useStoreon} from "@storeon/solidjs";
import { Link, useNavigate, useSearchParams} from "@solidjs/router";
import {Form, Button, FloatingLabel, Alert} from "solid-bootstrap";
import { createSignal } from "solid-js";

export default props => {
    const [state, dispatch] = useStoreon();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [credentialError, setCredentialError] = createSignal(false);

    let emailInput, passwordInput;

    const doLogin = async () => {

        const result = await state.agents.users.login(emailInput.value, passwordInput.value);
        if (!result.errorCode) {
            dispatch("pullUser", { refetch: true });
            return navigate(searchParams.r ? atob(searchParams.r) : "/");
        }
        setCredentialError(true);
    };

    return (
        <div class="login">
            <h2 class="mb-4 text-center">Connectez-vous</h2>
            <div class="row justify-content-center">
                <div class="col-12 col-md-5">
                    <div class="bg-white p-4">
                        <Show when={searchParams.first !== undefined}>
                            <Alert variant="info">Vous devez d'abord vous connecter à votre espace pour accéder à cette page.</Alert>
                        </Show>
                        <Form onSubmit={doLogin}>
                            <FloatingLabel controlId="email" label="Votre adresse email" className="mb-3">
                                <Form.Control type="email" autofocus autocomplete="username" placeholder="Votre adresse email" ref={emailInput} required />
                            </FloatingLabel>
                            <FloatingLabel controlId="password" label="Votre mot de passe" className="mb-3">
                                <Form.Control type="password" autocomplete="current-password" placeholder="Votre mot de passe" ref={passwordInput} required isInvalid={credentialError()} />
                                <Form.Control.Feedback type="invalid">Votre mot de passe est incorrect.</Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Group className="mb-3">
                                <Link href="/user/lost">Mot de passe perdu ?</Link>
                            </Form.Group>
                            <div class="d-grid">
                                <Button variant="primary" onClick={doLogin}>Me connecter</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}