import {useStoreon} from "@storeon/solidjs";
import {Link} from "@solidjs/router";
import FontAwesomeIcon from "../../components/fontawesome/FontAwesomeIcon";
import {faArrowCircleRight} from "@fortawesome/pro-duotone-svg-icons";
import {Show, For} from "solid-js";

export default () => {
    const [state] = useStoreon();

    const Institution = (props) => {

        return (
            <Link href={`/institution/${props.institution.token}`} class="item flex-fill">
                <div class="image position-absolute" style={`background-image: url(${props.institution.customFields && props.institution.customFields.logo ? props.institution.customFields.logo.source : "/images/logo_ccpl.jpg"}); background-size: 90%`}></div>
                <div class="item-content">
                    <div class="bg-white d-flex align-items-center justify-content-between">
                        {props.institution.code}
                        <FontAwesomeIcon icon={faArrowCircleRight} className={'pe-2'} primaryColor={"#FFFFFF"}
                                         secondaryColor="var(--bs-primary)" secondaryOpacity={"1"} size={"3x"}/>
                    </div>
                </div>
            </Link>
        );

    };

    return (
        <div class="container">
            <h2>Vos services :</h2>
            <Show when={state.loading.institutions === false} fallback={<div>Chargement des services...</div>}>
                <div class="d-flex align-items-center justify-content-between items flex-wrap">
                    <For
                        each={Object.values(state.institutions)}
                        fallback={<div class="institutions-list">Pas encore de services.</div>}
                    >
                        {(institution, index) => index() > 0 && (<Institution institution={institution}/>)}
                    </For>
                </div>
            </Show>
        </div>
    );
};
