import {useStoreon} from "@storeon/solidjs";
import {Link, useParams} from "@solidjs/router";
import FontAwesomeIcon from "../../../components/fontawesome/FontAwesomeIcon";
import {faChevronLeft, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {createEffect, createSignal} from "solid-js";
import createPrice from "../../../helpers/createPrice";
import moment from "moment";
import 'moment/locale/fr';
import { Alert, Button } from "solid-bootstrap";

export default props => {
    const [state, dispatch] = useStoreon();
    const {orderCode} = useParams();

    // On charge la commande.
    createEffect(() => dispatch("loadOrder", orderCode)); 

    const [error, setError] = createSignal(false);
    const [done, setDone] = createSignal(false);

    const doCancel = async () => {
        
        const result = await state.agents.orders.cancelOrder(orderCode);
        if (!result.success) {
            setError(true);
            return;
        }
        setDone(true);

    };

    return (
        <div class="customer">
            <h2 class="mb-4 text-center">Annuler votre commande</h2>
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 mx-auto">
                    <div class="p-4 bg-white">
                        <Show when={!state.loading.orders && state.orders[orderCode]} fallback="Chargement de votre commande...">
                            <Show when={error()}>
                                <Alert variant="danger">Une erreur s'est produite lors de l'annulation de votre commande.</Alert>
                            </Show>
                            <Show when={done()}>
                                <p class="text-center">
                                    <FontAwesomeIcon icon={faCheckCircle} size="4x" />
                                </p>
                                <p class="text-center">Votre commande a bien été annulée.</p>
                                <p class="text-center">Un e-mail vous a été envoyé pour confirmation.</p>
                                <p class="text-center"><Link class="btn btn-primary" href="/" replace>Continuer</Link></p>
                            </Show>
                            <Show when={!done()}>
                                <Show when={!(state.orders[orderCode] instanceof Error)} fallback={<Alert variant="danger">Une erreur s'est produite lors du chargement de votre commande.</Alert>}>
                                    <h3>Résumé</h3>
                                    <div class="row my-2">
                                        <div class="col">
                                            <strong>Numéro</strong><br />
                                            {orderCode}
                                        </div>
                                        <div class="col">
                                            <strong>Date</strong><br />
                                            {moment.utc(state.orders[orderCode].orderPlacedAt).local().format("DD/MM/YYYY HH:mm")}
                                        </div>
                                        <div class="col">
                                            <strong>Total TTC</strong><br />
                                            {createPrice(state.orders[orderCode].totalWithTax)} €
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <Show when={state.orders[orderCode].customFields.cancelable} fallback={<Alert variant="danger">Le délai pour pouvoir annuler est dépassé, veuillez nous contacter directement.</Alert>}>
                                            <Button variant="danger" onClick={doCancel}>Je confirme et j'annnule</Button>
                                        </Show>
                                    </div>
                                </Show>
                            </Show>
                        </Show>
                    </div>
                    <div className="mt-5"><Link className="btn btn-primary" href={`/institution/${state.token}`}><FontAwesomeIcon icon={faChevronLeft} className={'mr-2'}/> Retour</Link></div>
                </div>
            </div>
        </div>
    );
}