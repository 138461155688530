import CartDetails from "../../../components/cart/CartDetails";
import {useStoreon} from "@storeon/solidjs";
import {createEffect, createMemo, createSignal, For, Suspense, on} from "solid-js";
import {Accordion, Alert, Form} from "solid-bootstrap";
import FontAwesomeIcon from "../../../components/fontawesome/FontAwesomeIcon";
import {faWarning} from "@fortawesome/pro-light-svg-icons";
import createPrice from "../../../helpers/createPrice";
import goToPayment from "../../../helpers/goToPayment";
import {useNavigate} from "@solidjs/router";

export default props => {
    const [state, dispatch] = useStoreon();
    const navigate = useNavigate();
    const [shippingError, setShippingError] = createSignal();
    const [doingShipping, setDoingShipping] = createSignal(false);

    createEffect(async () => {
        // La commande est déjà passée au paiement
        if (state.cart && state.cart.state === "ArrangingPayment") {
            navigate("/order/payment", { replace: true });
            return;
        }

        // Le canal ne gère pas les méthodes de livraison
        if (state.institutions[state.token].customFields.skipShippingMethod) {
            // On définit la méthode de paiement par défaut
            await doShipping(state.institutions[state.token].customFields.defaultShippingMethod.id);
        }
    })

    createEffect(() => dispatch("loadShippingMethods"));
    const shippingMethods = createMemo(() => state.shippingMethods[state.token] || []);

    const onSelect = (eventKey) => eventKey !== null && (document.querySelector(`#shipping-method-${eventKey}`).checked = true);

    async function doShipping(shippingMethodId) {
        setDoingShipping(true);
        shippingMethodId = shippingMethodId || document.querySelector('[name="shipping_method"]:checked').value;
        const result = await state.agents.cart.setOrderShippingMethod(shippingMethodId);
        if (result.errorCode) {
            setShippingError(result.message);
        } else {
            setShippingError(null);
            await goToPayment([state, dispatch], navigate);
        }
    }

    return (
        <div class="customer">
            <h2 class="mb-4 text-center">Méthode de livraison</h2>
            <div class="row justify-content-center">
                <div class="col-12 col-md-8">
                    <Show when={shippingError()}>
                        <Alert variant="danger"><FontAwesomeIcon icon={faWarning} /> {shippingError()}</Alert>
                    </Show>
                    <Show when={!doingShipping()} fallback="Nous prenons en compte votre choix...">
                        <Suspense fallback="Chargement des méthodes de livraison en cours...">
                            <Accordion defaultActiveKey={0} onSelect={onSelect}>
                                <For each={shippingMethods()} fallback="Aucune méthode de livraison n'a été trouvée">{(method, index) => (
                                    <Accordion.Item eventKey={index()}>
                                        <Accordion.Header>
                                            <span class="d-flex flex-row w-100 align-items-center">
                                                <Form.Check type="radio" label="" value={method.id} name="shipping_method" id={`shipping-method-${index()}`} checked={index() === 0} />
                                                <span class="ms-2">{method.name}</span>
                                                <span class="ms-auto me-2 fw-bold">{method.priceWithTax === 0 ? "Gratuit" : createPrice(method.priceWithTax) + " €"}</span>
                                            </span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Show when={method.description.length > 0}>
                                                <div class="mb-4" innerHTML={method.description} />
                                            </Show>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}</For>
                            </Accordion>
                        </Suspense>
                    </Show>
                </div>
                <div class="col-12 col-md-4 mt-4 mt-md-0">
                    <CartDetails order={true} secured={true} validate={false} shipping={true} doShipping={doShipping} />
                </div>
            </div>
        </div>
    );
}