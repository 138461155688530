import {useStoreon} from "@storeon/solidjs";
import {useParams, Link} from "@solidjs/router";
import {Form, Button, FloatingLabel, Alert} from "solid-bootstrap";
import { createSignal } from "solid-js";
import FontAwesomeIcon from "../../components/fontawesome/FontAwesomeIcon";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default props => {
    const [state] = useStoreon();
    const params = useParams();

    const [error, setError] = createSignal(false);
    const [done, setDone] = createSignal(false);

    let passwordInput;

    const doReset = async () => {

        const result = await state.agents.users.resetPassword(params.token, passwordInput.value);
        if (result.errorCode) {
            let message = 'Une erreur est survenue lors de la vérification de votre compte.';
            if (result.errorCode === "PASSWORD_RESET_TOKEN_INVALID_ERROR") {
                message = 'Le jeton de remise à zero est invalide ou expiré.';
            }
            setError(message);
            return;
        }
        setDone(true);

    };

    return (
        <div class="createCustomer">
            <h2 class="mb-4 text-center">Votre nouveau mot de passe</h2>
            <div class="row justify-content-center">
                <div class="col-12 col-md-5">
                    <div class="bg-white p-4">
                        <Show when={error()}>
                            <Alert variant="danger">{error()}</Alert>
                        </Show>
                        <Show when={done()}>
                            <p class="text-center">
                                <FontAwesomeIcon icon={faCheckCircle} size="4x" />
                            </p>
                            <p class="text-center">Votre nouveau mot de passe a bien été pris en compte.</p>
                            <p class="text-center"><Link class="btn btn-primary" href="/user/login" replace>Me connecter</Link></p>
                        </Show>
                        <Show when={!done()}>
                            <Form>
                                <h6 class="text-center">Entrez le mot de passe de votre choix</h6>
                                <FloatingLabel controlId="password" label="Votre mot de passe" className="mb-3">
                                    <Form.Control type="password" autofocus autocomplete="new-password" placeholder="Votre mot de passe" ref={passwordInput} />
                                </FloatingLabel>
                                <div class="d-grid">
                                    <Button variant="primary" onClick={doReset}>Définir mon mot de passe</Button>
                                </div>
                            </Form>
                        </Show>
                    </div>
                </div>
            </div>
        </div>
    );
}