import {useStoreon} from "@storeon/solidjs";
import {Link, useParams} from "@solidjs/router";
import FontAwesomeIcon from "../../../components/fontawesome/FontAwesomeIcon";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {createEffect} from "solid-js";

export default props => {
    const [state, dispatch] = useStoreon();
    const {orderCode} = useParams();

    createEffect(() => dispatch("loadOrder", orderCode)); 

    return (
        <div class="customer">
            <h2 class="mb-4 text-center">Votre commande est terminée !</h2>
            <p class="mb-4 text-center">Merci !</p>
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 mx-auto">
                    <div class="p-4 bg-white">
                        <Show when={!state.loading.orders && state.orders[orderCode]} fallback="Chargement de votre commande...">
                            <h3>Résumé</h3>
                            <p class="my-2">
                                <strong>Un email a été envoyé à :</strong><br />
                                {state.orders[orderCode].customer.emailAddress}
                            </p>
                            <p class="my-2">
                                <strong>Numéro de commande :</strong><br />
                                {orderCode}
                            </p>
                        </Show>
                    </div>
                    <div className="mt-5"><Link className="btn btn-primary" href={`/institution/${state.token}`}><FontAwesomeIcon icon={faChevronLeft} className={'mr-2'}/> Retour</Link></div>
                </div>
            </div>
        </div>
    );
}