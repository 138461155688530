import FontAwesomeIcon from "../../components/fontawesome/FontAwesomeIcon";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {createSignal, Show, For, createEffect} from "solid-js";
import moment from "moment";
import 'moment/locale/fr';
import {useStoreon} from "@storeon/solidjs";
import {Link, useParams} from "@solidjs/router";

export default ({handleBooking}) => {
    const [state] = useStoreon();
    const params = useParams();

    const [selectedDate, setSelectedDate] = createSignal();
    const [currentDate, setCurrentDate] = createSignal("");
    createEffect(() => { params.activityId; setCurrentDate("") });

    const onTimeClick = (booking, e) => {
        e.preventDefault();
        handleBooking(booking);
    };

    const onAvailabilityClick = (availability, e) => {
        e.preventDefault();
        const itemHoursElement = document.querySelector("#item-hours");
        setSelectedDate(moment(availability.date).utcOffset(availability.schedule.utcOffset).format("DD/MM/YYYY"));
        window.scrollTo({
            left: 0,
            top: itemHoursElement.offsetTop
        });
    };

    const Availability = ({availability}) => {

        const date = moment(availability.date).utcOffset(availability.schedule.utcOffset);
        if (currentDate() !== date.format("DD/MM/YYYY")) {

            setCurrentDate(date.format("DD/MM/YYYY"));
            date.locale('fr');

            return (
                <h3 onClick={[onAvailabilityClick, availability]} class="d-inline-flex flex-column text-center" classList={{active: moment(availability.date).utcOffset(availability.schedule.utcOffset).format("DD/MM/YYYY") === selectedDate()}}>
                    <span class="dayofweek text-uppercase">{date.format("dddd")}</span>
                    <span class="day">{date.format("DD")}</span>
                    <span class="month text-uppercase">{date.format("MMMM")}</span>
                    <span class="year">{date.format("YYYY")}</span>
                </h3>
            );

        }

    };

    const Times = ({availability, selectedDate}) => (
        <Show when={moment(availability.date).utcOffset(availability.schedule.utcOffset).format("DD/MM/YYYY") === selectedDate()}>
            {availability.available > 0 ? (
                <div class="btn btn-white">
                    <a class="d-flex justify-items-center flex-column align-items-center" href="#" onClick={[onTimeClick, {channelToken: params.channelToken, activityId: params.activityId, scheduleId: availability.schedule.id, utcOffset: availability.schedule.utcOffset, date: availability.date, available: availability.available, variantId: state.activities[params.activityId].variants[0].id, price: state.activities[params.activityId].variants[0].priceWithTax}]}><span class="time">{moment.utc(availability.date).utcOffset(availability.schedule.utcOffset).format("HH:mm")}</span><span class="availabilities">{availability.available} places disponibles</span></a>
                </div>
            ) : (
                <div class="btn btn-disabled">
                    <div class="d-flex justify-items-center flex-column align-items-center"><span class="time">{moment.utc(availability.date).utcOffset(availability.schedule.utcOffset).format("HH:mm")}</span><span class="availabilities">0 place disponible</span></div>
                </div>
            )}
        </Show>
    );

    return (
        <div class="container activity">
            <Show when={state.loading.availabilities === false} fallback={<p>Chargement des dates...</p>}>
                <h2>Sélectionnez votre date pour <span class="d-block d-md-inline">&laquo; {state.activities[params.activityId] ? state.activities[params.activityId].name : ''} &raquo; :</span></h2>
                <div id="items" className="d-flex align-items-center justify-content-center flex-wrap">
                    <For
                        each={state.availabilities[params.activityId]}
                        fallback={"Aucune date disponible pour cette activité"}>
                        {availability => (
                            <Availability availability={availability} />
                        )}
                    </For>
                    <div id="item-hours" class="w-100">
                        {selectedDate() ? (<h2>Choisir une heure pour la date sélectionnée ci-dessus :</h2>) : (<></>)}
                        <div class="d-flex justify-content-center flex-wrap align-items-center">
                            <For each={state.availabilities[params.activityId]}>
                                {availability => (
                                    <Times availability={availability} selectedDate={selectedDate} onSetBooking={handleBooking} />
                                )}
                            </For>
                        </div>
                    </div>
                </div>
                <div className="mt-5"><Link className="btn btn-primary" href={`/institution/${params.channelToken}`}><FontAwesomeIcon icon={faChevronLeft} className={'mr-2'}/> Retour</Link></div>
            </Show>
        </div>
    );
};
