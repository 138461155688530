import {useStoreon} from "@storeon/solidjs";
import {Link} from "@solidjs/router";
import {Form, Button, FloatingLabel, Alert} from "solid-bootstrap";
import { createSignal } from "solid-js";
import FontAwesomeIcon from "../../components/fontawesome/FontAwesomeIcon";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default props => {
    const [state] = useStoreon();

    const [error, setError] = createSignal(false);
    const [done, setDone] = createSignal(false);
    const [badEmail, setBadEmail] = createSignal(false);

    let emailInput;

    const doRequest = async () => {

        const email = emailInput.value;
        if (email.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i)) {
            setBadEmail(false);

            const result = await state.agents.users.requestPasswordReset(email);
            if (!result.success) {
                setError(true);
                return;
            }
            setDone(true);

        } else {
            setBadEmail(true);
        }

    };

    return (
        <div class="createCustomer">
            <h2 class="mb-4 text-center">{done() ? "Lien de réinitialisation envoyé" : "Mot de passe oublié ?"}</h2>
            <div class="row justify-content-center">
                <div class="col-12 col-md-5">
                    <div class="bg-white p-4">
                        <Show when={error()}>
                            <Alert variant="danger">Une erreur est survenue lors de la récupération de votre mot de passe.</Alert>
                        </Show>
                        <Show when={done()}>
                            <p class="text-center">
                                <FontAwesomeIcon icon={faCheckCircle} size="4x" />
                            </p>
                            <p class="text-center">Un e-mail vous a été envoyé à l'adresse indiquée.<br />Il contient un lien pour réinitialiser votre mot de passe</p>
                            <p class="text-center">Votre mot de passe vous est revenu ?<br />Cliquez ci-dessous pour vous connecter</p>
                            <p class="text-center"><Link class="btn btn-primary" href="/user/login" replace>Me connecter</Link></p>
                        </Show>
                        <Show when={!done()}>
                            <Form>
                                <p class="text-center">Renseignez votre adresse email. Nous vous enverrons un lien vous permettant de réinitialiser votre mot de passe.</p>
                                <FloatingLabel controlId="email" label="Votre adresse e-mail" className="mb-3">
                                    <Form.Control type="email" autofocus placeholder="Votre adresse e-mail" ref={emailInput} isInvalid={badEmail()} />
                                    <Form.Control.Feedback type="invalid">Votre e-mail est incorrect.</Form.Control.Feedback>
                                </FloatingLabel>
                                <div class="d-grid">
                                    <Button variant="primary" onClick={doRequest}>Récupérer</Button>
                                </div>
                            </Form>
                        </Show>
                    </div>
                </div>
            </div>
        </div>
    );
}