import {useNavigate, useParams} from "@solidjs/router";
import {useStoreon} from "@storeon/solidjs";
import {createMemo} from "solid-js";

/*#if _ES
import {lazy} from "solid-js";
const User = lazy(() => import("./User"));
//#else */
import User from "./User";
//#endif

export default function (props) {
    const [, dispatch] = useStoreon();
    const params = useParams();
    const navigate = useNavigate();

    const action = createMemo(() => params.action && ["create", "lost", "reset", "login", "logout"].indexOf(params.action) !== -1 ? params.action : "login");

    if (action() === "logout") {
        dispatch("logout");
        return navigate("/", {replace: true})
    }

    return User({action});
}
