import {createEffect, useTransition} from "solid-js";
import {useStoreon} from "@storeon/solidjs";
import {useNavigate, useParams} from "@solidjs/router";

/*#if _ES
import {lazy} from "solid-js";
const Activity = lazy(() => import("./Activity"));
//#else */
import Activity from "./Activity";
//#endif

export default function () {
    const [, dispatch] = useStoreon();
    const params = useParams();
    const [, start] = useTransition();
    const navigate = useNavigate();

    dispatch("setToken", params.channelToken);
    createEffect(() => dispatch("loadActivity", params.activityId));
    createEffect(() => dispatch("loadAvailabilities", params.activityId));

    const handleBooking = (booking) => {
        start(() => {
            dispatch("setBooking", booking);
            navigate("/booking");
        });
    };

    return Activity({handleBooking});
}
