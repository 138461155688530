import {useStoreon} from "@storeon/solidjs";
import {useNavigate, useParams} from "@solidjs/router";
import {Form, Button, FloatingLabel, Alert} from "solid-bootstrap";
import { createSignal } from "solid-js";

export default props => {
    const [state, dispatch] = useStoreon();
    const params = useParams();
    const navigate = useNavigate();

    const [error, setError] = createSignal(false);

    let passwordInput;

    const doVerify = async () => {

        const result = await state.agents.users.verifyCustomerAccount(params.token, passwordInput.value);
        if (!result.id) {
            setError(true);
            return;
        }
        navigate("/", { replace: true });

    };

    return (
        <div class="createCustomer">
            <h2 class="mb-4 text-center">Créer votre compte</h2>
            <div class="row justify-content-center">
                <div class="col-12 col-md-5">
                    <div class="bg-white p-4">
                        <Show when={error()}>
                            <Alert variant="danger">Une erreur est survenue lors de la vérification de votre compte.</Alert>
                        </Show>
                        <Form>
                            <h6 class="text-center">Entrez le mot de passe de votre choix</h6>
                            <FloatingLabel controlId="password" label="Votre mot de passe" className="mb-3">
                                <Form.Control type="password" autofocus autocomplete="new-password" placeholder="Votre mot de passe" ref={passwordInput} />
                            </FloatingLabel>
                            <div class="d-grid">
                                <Button variant="primary" onClick={doVerify}>Continuer</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}