import {createEffect} from "solid-js";
import {useStoreon} from "@storeon/solidjs";
import {useNavigate, useParams} from "@solidjs/router";

/*#if _ES
import {lazy} from "solid-js";
const Institution = lazy(() => import("./Institution"));
//#else */
import Institution from "./Institution";
//#endif

export default function () {
    const [, dispatch] = useStoreon();
    const {channelToken} = useParams();

    if (!channelToken) {
        useNavigate()("/");
    }

    dispatch("setToken", channelToken);
    createEffect(() => dispatch("loadInstitution"));
    createEffect(() => dispatch("loadActivities"));

    return Institution({channelToken});
}
