import {Form, Button, FloatingLabel, Card, ListGroup} from "solid-bootstrap";
import {createMemo, createSignal, Show} from "solid-js";
import {useNavigate, Link} from "@solidjs/router";
import {useStoreon} from "@storeon/solidjs";
import CartDetails from "../../../components/cart/CartDetails";

export default props => {
    const [state, dispatch] = useStoreon();
    const [customerExist, setCustomerExist] = createSignal(false);
    const [credentialError, setCredentialError] = createSignal(false);
    const [needAddress, setNeedAddress] = createSignal(false);
    const [hasAddress, setHasAddress] = createSignal(false);
    const [badEmail, setBadEmail] = createSignal(false);
    const [addressFormValidated, setAddressFormValidated] = createSignal(false);
    const navigate = useNavigate();

    if (state.currentUser && state.currentUser.id || state.cart && state.cart.customer) {
        // Si on a son adresse, on passe à la méthode de livraison.
        if (state.currentUser && state.currentUser.addresses && state.currentUser.addresses.length > 0 || state.cart && state.cart.billingAddress) {
            navigate("/order/shipping");
        }
    }

    let emailInput, passwordInput, addressForm;

    const onSubmit = (e) => {
        e.preventDefault();
        customerExist() ? doLogin() : doCheckEmail();
    }

    const doCheckEmail = async () => {
        const email = emailInput.value;
        if (email.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i)) {
            const result = (await state.agents.users.checkEmail(email)).customerExist;
            setCustomerExist(result);
            setNeedAddress(!result);
            setBadEmail(false);
        } else {
            setBadEmail(true);
        }
    };

    const doLogin = async (e) => {
        const result = await state.agents.users.login(emailInput.value, passwordInput.value);
        setCredentialError(typeof result.errorCode !== "undefined");
        if (!result.errorCode) {
            const cart = await state.agents.cart.getCurrent();
            dispatch("setCart", cart);
            const currentUser = await state.agents.users.current();
            dispatch("setCurrentUser", currentUser);
        
            if (currentUser.addresses.length === 0) {
                setNeedAddress(true);
            } else {   
                if (cart.state === "ArrangingPayment") {
                    navigate("/order/payment");
                } else {
                    navigate("/order/shipping");
                }
            }
            
        }
    }

    const doCreateAddress = async () => {

        // Le formulaire est valide
        if (addressForm.checkValidity()) {

            // On récupère les données du formulaire
            const formData = new FormData(addressForm);
            const data = Object.fromEntries(formData.entries());

            // On doit peut être créer son compte
            if (!customerExist()) {
                let result = await state.agents.users.registerCustomerAccount({
                    emailAddress: emailInput.value,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phoneNumber: data.phoneNumber
                });
                if (!result.success) {
                    return console.error(result.message);
                }

                result = await state.agents.cart.setCustomerForOrder({
                    emailAddress: emailInput.value,
                    firstName: data.firstName,
                    lastName: data.lastName
                });
                if (result.errorCode) {
                    return console.error(result.message);
                }
            }
            
            const address = {
                fullName: "Mon adresse",
                streetLine1: data.streetLine1,
                streetLine2: data.streetLine2,
                city: data.city,
                postalCode: data.postalCode,
                countryCode: data.countryCode,
                phoneNumber: data.phoneNumber,
                defaultShippingAddress: true,
                defaultBillingAddress: true
            };

            // On crée l'adresse.
            await state.agents.cart.createCustomerAddress(address);
            await state.agents.cart.setOrderBillingAddress(address);

            const cart = await state.agents.cart.getCurrent();
            console.log(cart);
            dispatch("setCart", cart);
            
            if (cart.state === "ArrangingPayment") {
                navigate("/order/payment");
            } else {
                navigate("/order/shipping");
            }
            
            return;
        }

        setAddressFormValidated(true);

    }

    const address = createMemo(() => state.currentUser && state.currentUser.addresses && state.currentUser.addresses[0] || state.cart && state.cart.billingAddress);

    return (
        <div class="customer">
            <h2 class="mb-4 text-center">{hasAddress() ? "Vos coordonnées" : "Connectez-vous ou créer votre compte"}</h2>
            <div class="row justify-content-center">
                <Show when={hasAddress()}>
                    <div class="col-12 col-md-8">
                        <Card>
                            <Card.Header>{address().fullName}</Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item>{address().streetLine1}</ListGroup.Item>
                                <Show when={address().streetLine2 !== ""}>
                                    <ListGroup.Item>{address().streetLine2}</ListGroup.Item>
                                </Show>
                                <ListGroup.Item>{address().postalCode} {address().city}</ListGroup.Item>
                                <ListGroup.Item>{address().country}</ListGroup.Item>
                                <ListGroup.Item>{address().phoneNumber}</ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </div>
                    <div class="col-4">
                        <CartDetails order={true} validate={false} customer={true} payment={false} />
                    </div>
                </Show>
                <Show when={!hasAddress()}>
                    <div class="col-12 col-md-5">
                        <Show when={!needAddress()}>
                            <h5 class="text-center my-2">Continuer avec mon adresse email</h5>
                            <div class="bg-white p-4">
                                <Form onSubmit={onSubmit}>
                                    <Show when={customerExist()}>
                                        <h6 class="text-center">Vous avez déjà un compte chez nous ?</h6>
                                    </Show>
                                    <FloatingLabel controlId="email" label="Votre e-mail" className="mb-3">
                                        <Form.Control type="email" placeholder="Votre e-mail" ref={emailInput} isInvalid={badEmail()} />
                                        <Form.Control.Feedback type="invalid">Votre e-mail est incorrect.</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <Show when={customerExist()}>
                                        <FloatingLabel controlId="password" label="Votre mot de passe" className="mb-3">
                                            <Form.Control type="password" autocomplete="current-password" placeholder="Votre mot de passe" ref={passwordInput} isInvalid={credentialError()} />
                                            <Form.Control.Feedback type="invalid">Votre mot de passe est incorrect.</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <Form.Group className="mb-3">
                                            <Link href="/user/lost">Mot de passe perdu ?</Link>
                                        </Form.Group>
                                    </Show>
                                    <div class="d-grid">
                                        <Show when={!customerExist()}>
                                            <Button variant="primary" onClick={doCheckEmail}>Continuer</Button>
                                        </Show>
                                        <Show when={customerExist()}>
                                            <Button variant="primary" onClick={doLogin}>Connexion</Button>
                                        </Show>
                                    </div>
                                </Form>
                            </div>
                        </Show>
                        <Show when={needAddress()}>
                            <h5 className="text-center my-2">Votre adresse de facturation</h5>
                            <div className="bg-white p-4">
                                <Form noValidate validated={addressFormValidated()} ref={addressForm}>
                                    <FloatingLabel controlId="firstName" label="Prénom" className="mb-3">
                                        <Form.Control name="firstName" type="text" placeholder="Prénom" required value={state.currentUser && state.currentUser.firstName} />
                                        <Form.Control.Feedback type="invalid"><strong>Prénom</strong> est obligatoire.</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="lastName" label="Nom" className="mb-3">
                                        <Form.Control name="lastName" type="text" placeholder="Nom" required value={state.currentUser && state.currentUser.lastName} />
                                        <Form.Control.Feedback type="invalid"><strong>Nom</strong> est obligatoire.</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="streetLine1" label="Adresse" className="mb-3">
                                        <Form.Control name="streetLine1" type="text" placeholder="Adresse" required />
                                        <Form.Control.Feedback type="invalid"><strong>Adresse</strong> est obligatoire.</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="streetLine2" label="Complément d'adresse" className="mb-3">
                                        <Form.Control name="streetLine2" type="text" placeholder="Complément d'adresse" />
                                    </FloatingLabel>
                                    <FloatingLabel controlId="postalCode" label="Code postal" className="mb-3">
                                        <Form.Control name="postalCode" type="text" placeholder="Code postal" required />
                                        <Form.Control.Feedback type="invalid"><strong>Code postal</strong> est obligatoire.</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="city" label="Ville" className="mb-3">
                                        <Form.Control name="city" type="text" placeholder="Ville" required />
                                        <Form.Control.Feedback type="invalid"><strong>Ville</strong> est obligatoire.</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="country" label="Pays" className="mb-3">
                                        <Form.Select name="countryCode" required>
                                            <option>Pays</option>
                                            <option value="fr" selected>France</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid"><strong>Pays</strong> est obligatoire.</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="phoneNumber" label="N° de téléphone" className="mb-3">
                                        <Form.Control name="phoneNumber" type="text" placeholder="N° de téléphone" required />
                                        <Form.Control.Feedback type="invalid"><strong>N° de téléphone</strong> est obligatoire.</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <div className="d-grid">
                                        <Button variant="primary" onClick={doCreateAddress}>Suivant</Button>
                                    </div>
                                </Form>
                            </div>
                        </Show>
                    </div>
                </Show>
            </div>
        </div>
    );
}