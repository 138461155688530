import CartDetails from "../../../components/cart/CartDetails";
import {useStoreon} from "@storeon/solidjs";
import {createEffect, createMemo, createSignal, For, Suspense} from "solid-js";
import {Accordion, Alert, Button, Form} from "solid-bootstrap";
import FontAwesomeIcon from "../../../components/fontawesome/FontAwesomeIcon";
import {faLock, faWarning} from "@fortawesome/pro-light-svg-icons";
import createPrice from "../../../helpers/createPrice";
import {useNavigate} from "@solidjs/router";

export default props => {
    const [state, dispatch] = useStoreon();
    const navigate = useNavigate();
    const [paymentError, setPaymentError] = createSignal();

    createEffect(() => dispatch("loadPaymentMethods"));
    const paymentMethods = createMemo(() => state.paymentMethods[state.token] || []);

    const onSelect = (eventKey) => eventKey !== null && (document.querySelector(`#payment-method-${eventKey}`).checked = true);

    async function doPayment(code) {

        // PayFiP
        if (code.indexOf("payfip") !== -1) {
            const result = await state.agents.cart.createPayFipPaymentIntent(code);
            if (result.errorCode) {
                setPaymentError(result.message);
                return;
            } else {
                setPaymentError(null);
                if (result.url) {

                    window.location.href = result.url;
                    return;
                }
            }
        }

        // Test
        else if (code.indexOf("test") !== -1) {
            const result = await state.agents.cart.createTestPaymentIntent(code);
            if (result.errorCode) {
                setPaymentError(result.message);
                return;
            } else {
                setPaymentError(null);
            }
        }

        // Sur place
        else if (code.indexOf("sur-place") !== -1) {
            const result = await state.agents.cart.addPaymentToOrder(code);
            if (result.errorCode) {
                setPaymentError(result.message);
                return;
            } else {
                setPaymentError(null);
            }
        }

        const currentCode = state.cart.code;

        const cart = await state.agents.cart.getCurrent();
        dispatch("setCart", cart);

        navigate("/order/confirm/" + currentCode);

    }

    return (
        <div class="customer">
            <h2 class="mb-4 text-center">Choix du paiement</h2>
            <p class="mb-4 text-center">En validant ma commande, je déclare avoir pris connaissance et accepter sans réserve : <a href="">Les CGV</a></p>
            <div class="row justify-content-center">
                <div class="col-12 col-md-8">
                    <Show when={paymentError()}>
                        <Alert variant="danger"><FontAwesomeIcon icon={faWarning} /> {paymentError()}</Alert>
                    </Show>
                    <Suspense fallback="Chargement des méthodes de paiement en cours...">
                        <Accordion defaultActiveKey={0} onSelect={onSelect}>
                            <For each={paymentMethods()} fallback="Aucune méthode de paiement n'a été trouvée">{(method, index) => (
                                <Accordion.Item eventKey={index()}>
                                    <Accordion.Header>
                                        <Form.Check type="radio" label="" name="payment_method" id={`payment-method-${index()}`} checked={index() === 0} />
                                        <span class="ml-4">{method.name}</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Show when={method.description.length > 0}>
                                            <div class="mb-4" innerHTML={method.description} />
                                            <Show when={method.code.indexOf("payfip") !== -1}>
                                                <hr class="mb-4" />
                                            </Show>
                                        </Show>
                                        <Show when={method.code.indexOf("payfip") !== -1}>
                                            <div class="mb-4">En cliquant sur "Payer" vous serez redirigé vers le site de PayFiP.</div>
                                        </Show>
                                        <Button variant="primary" size="lg" onClick={() => doPayment(method.code)}>
                                            <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>&nbsp;
                                            <Show when={state.cart && state.cart.totalWithTax > 0 && method.code.indexOf("payfip") !== -1} fallback="Valider ma commande">Payer {createPrice(state.cart.totalWithTax)} €</Show>
                                        </Button>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}</For>
                        </Accordion>
                    </Suspense>
                </div>
                <div class="col-12 col-md-4 mt-4 mt-md-0">
                    <CartDetails order={true} secured={true} validate={false} />
                </div>
            </div>
        </div>
    );
}