import {createEffect, createMemo} from "solid-js";
import {useStoreon} from "@storeon/solidjs";
import {useNavigate, useParams} from "@solidjs/router";

/*#if _ES
import {lazy} from "solid-js";
const Order = lazy(() => import("./Order"));
//#else */
import Order from "./Order";
//#endif

export default function () {
    const [state, dispatch] = useStoreon();
    const params = useParams();
    const navigate = useNavigate();

    createEffect(() => dispatch("loadCart"));
    createEffect(() => dispatch("pullUser"));

    const step = createMemo(() => params.step && ["cart", "customer", "shipping", "payment", "confirm", "cancel"].indexOf(params.step) !== -1 ? params.step : "cart");
    createEffect(() => {
        if (!state.token && step() !== "cancel" && step() !== "confirm") {
            return navigate("/", { replace: true });
        }
    })

    return Order({step});
}
