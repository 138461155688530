// Icons
import FontAwesomeIcon from "../../components/fontawesome/FontAwesomeIcon";
import {faArrowCircleRight} from "@fortawesome/pro-duotone-svg-icons";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import { Link } from "@solidjs/router";
import {useStoreon} from "@storeon/solidjs";
import {createMemo, For, Show} from "solid-js";

const Activity = (props) => {

    return (
        <Link href={`/institution/${props.channelToken}/activity/${props.activity.id}`} class="item">
            <div class="image position-absolute" style={`background-image: url(${props.activity.featuredAsset ? props.activity.featuredAsset.source : "/images/logo_ccpl.jpg"}); background-size: ${props.activity.featuredAsset ? 'cover' : '90%'}`}></div>
            <div class="item-content">
                <div class="bg-white d-flex align-items-center justify-content-between">
                    {props.activity.name}
                    <FontAwesomeIcon icon={faArrowCircleRight} className={'px-2'} primaryColor={"#FFFFFF"} secondaryColor={"#003C7F"} secondaryOpacity={"1"} size={"3x"} />
                </div>
            </div>
        </Link>
    );

};

export default ({channelToken}) => {
    const [state] = useStoreon();

    const activities = createMemo(() => state.activities && Object.values(state.activities));

    return (
        <div class="container">
            <Show when={state.loading.activities === false} fallback={<p>Chargement des activités...</p>}>
                <h2>Sélectionnez votre activité :</h2>
                <div class="d-flex align-items-center items flex-wrap">
                    <For each={activities()} fallback={<p>Aucune activité disponible pour cet établissement</p>}>{(activity) => (<
                        Activity activity={activity} channelToken={channelToken} />
                    )}</For>
                </div>
                <div class="mt-5"><Link class="btn btn-primary" href="/"><FontAwesomeIcon icon={faChevronLeft} className={'mr-2'} /> Retour</Link></div>
            </Show>
        </div>
    );
};
